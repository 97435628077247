import React from "react";


const Cv = () => {

    const handleDownload = () => {
      const link = document.createElement('a');
      link.href = 'https://coderalan.com/alanRojasDesarrolladorWeb.pdf'; 
      link.target = '_blank'
      link.download = 'CurriculumAlanRojas.pdf'; 
      link.click();
    };

  return (

    <div>
      <button className="btnDCV" onClick={handleDownload}>Descargar Cv</button>
    </div>

  );

}

export default Cv;