import React, { useEffect, useState } from "react";
import fetchProjects from '../../services/getProjects';
import ModalProjects from "./ModalProject";
import IconP from "./IconP"

import { ReactComponent as SvgIc } from "../../images/calender-svgrepo-com.svg";
import { ReactComponent as SvgDiamong } from "../../images/diamond-svgrepo-com.svg";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isModalProjectOpen, setIsModalProjectOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const openModalP = (project) => {
    setSelectedProject(project);
    setIsModalProjectOpen(true);
  };

  const closeModalP = () => {
    setIsModalProjectOpen(false);
    setSelectedProject(null);
  };

  useEffect(() => {
    const loadProjects = async () => {
      setLoading(true);
      try {
        const projectData = await fetchProjects();
        setProjects(projectData);
      } catch (err) {
        setError('No se pudieron cargar los proyectos.');
      } finally {
        setLoading(false);
      }
    };

    loadProjects();
  }, []);

  if (loading) return <p>Cargando proyectos...</p>;
  if (error) return <p>{error}</p>;

  return (
    <article className="Projects">
      <h2>Proyectos</h2>
      {projects.map(project => (
        <div className="BoxP" key={project.idProjects}>
          <h3 className="BoxPH3">{project.title}</h3>
          <p className="siteProject">{project.site}</p>
          <div className="dateContainerP">
            <IconP SvgIconC={SvgIc} />
            <p className="fechaProject">{project.dateOfCreate}</p>
          </div>
          <div className="stackContainerP">
            <IconP SvgIconC={SvgDiamong} />
            <p className="stackProject">{project.stack}</p>
          </div>
          <button
            onClick={() => {
              if (project.btn.toLowerCase() === "ver") {
                openModalP(project);
              } else {
                window.open(project.url, "_blank");
              }
            }}
            className="demoProjectsA"
          >
            {project.btn}
          </button>
        </div>
      ))}
      <ModalProjects
        isOpen={isModalProjectOpen}
        onClose={closeModalP}
        project={selectedProject}
      />
    </article>
  );
}

export default Projects;
