import React from "react";
import Tech from "./Tech";

import { ReactComponent as SvgHtml } from "../../images/html.svg";
import { ReactComponent as SvgCss } from "../../images/css.svg";
import { ReactComponent as SvgJS } from "../../images/javascript.svg";
import { ReactComponent as SvgBoot } from "../../images/bootstrap-svgrepo-com.svg";
import { ReactComponent as SvgReact } from "../../images/reactjs-svgrepo-com.svg";
import { ReactComponent as SvgAngular } from "../../images/angular-icon-svgrepo-com.svg";

//backend

import { ReactComponent as SvgPhp } from "../../images/php-svgrepo-com.svg";
import { ReactComponent as SvgLarav } from "../../images/laravel-svgrepo-com.svg";
import { ReactComponent as SvgNode } from "../../images/node-js-svgrepo-com.svg";
import { ReactComponent as SvgApache } from "../../images/apahce.svg";


//Database

import { ReactComponent as SvgMysql } from "../../images/mysql-logo-svgrepo-com.svg";
import { ReactComponent as SvgPhpMyAdmin } from "../../images/phpmyadmin-svgrepo-com.svg";
import { ReactComponent as SvgDer } from "../../images/network-shapes-svgrepo-com.svg";

//VCS

import { ReactComponent as SvgGit } from "../../images/git-svgrepo-com.svg";
import { ReactComponent as SvgGitHub } from "../../images/github-color-svgrepo-com.svg";

//Deployment

import { ReactComponent as SvgCpanel } from "../../images/cpanel.svg";
import { ReactComponent as SvgFtp } from "../../images/ftp-upload-svgrepo-com.svg";

//Development Principles

import { ReactComponent as SvgPoo } from "../../images/shapes-svgrepo-com.svg";
import { ReactComponent as  SvgApi } from "../../images/business-graphics-svgrepo-com.svg";
import { ReactComponent as  SvgMvc } from "../../images/stack-svgrepo-com.svg";

//Tools

import { ReactComponent as  SvgVsCode } from "../../images/visual-studio-code-svgrepo-com.svg";
import { ReactComponent as  SvgThunder } from "../../images/thunder-svgrepo-com.svg";
import { ReactComponent as  SvgTrello } from "../../images/trello.svg";





const Stack = () => {

    return(
        <article className="Stack pR">

            <h2>Tecnologias</h2>

            <div className="techFront">
                <div className="techContainer">
                    <h3>Front End</h3>
                    <div className="CT">
                        <Tech name="HTML 5" SvgIcon={SvgHtml}/>
                        <Tech name="CSS 3"  SvgIcon={SvgCss}/>
                        <Tech name="JavaScript" SvgIcon={SvgJS}/>
                        <Tech name="React.js" SvgIcon={SvgReact}/>
                    </div>
                </div>
                <div className="techContainer TcPT">
                    <h3>Back End</h3>
                    <div  className="CT">
                        <Tech name="PHP" SvgIcon={SvgPhp}/>
                        <Tech name="Laravel" SvgIcon={SvgLarav}/>
                        <Tech name="Node.js" SvgIcon={SvgNode}/>
                        <Tech name="Apache" SvgIcon={SvgApache}/>
                        
                    </div>
                </div>
                <div className="techContainer TcPT">
                    <h3>Data Base</h3>
                    <div  className="CT">
                        <Tech name="MySql" SvgIcon={SvgMysql}/>
                        <Tech name="phpMyAdmin" SvgIcon={SvgPhpMyAdmin}/>
                        <Tech name="Diagramas E-R" SvgIcon={SvgDer}/>
                    </div>
                </div>
                <div className="techContainer TcPT">
                    <h3>Version control</h3>
                    <div  className="CT">
                        <Tech name="Git" SvgIcon={SvgGit}/>
                        <Tech name="Git Hub" SvgIcon={SvgGitHub}/>
                    </div>
                </div>
                <div className="techContainer TcPT">
                    <h3>Deployment</h3>
                    <div  className="CT">
                        <Tech name="Cpanel" SvgIcon={SvgCpanel}/>
                        <Tech name="Core FTP" SvgIcon={SvgFtp}/>
                    </div>
                </div>
                <div className="techContainer TcPT">
                    <h3>Development Principles</h3>
                    <div  className="CT">
                        <Tech name="Model View Controller" SvgIcon={SvgMvc}/>
                        <Tech name="Object Oriented Programming" SvgIcon={SvgPoo}/>
                        <Tech name="API REST" SvgIcon={SvgApi}/>
                    </div>
                </div>
                <div className="techContainer TcPT">
                    <h3>Tools</h3>
                    <div  className="CT">
                        <Tech name="VS Code" SvgIcon={SvgVsCode}/>
                        <Tech name="ThunderClient" SvgIcon={SvgThunder}/>
                        <Tech name="Trello" SvgIcon={SvgTrello}/>
                    </div>
                </div>
            </div>

        </article>
    )

}


export default Stack;