import React from "react";
import ContactMe from "../contactMe/ContactMe";
import Cv from "../Cv/Cv";
import imgProfile from '../../images/profile.png'
import imgBKTH from '../../images/imgTopHeader2.webp'
import imgRobles from '../../images/LogoRobles.png'

const Header = () =>{

    return(
        <header className="Header">
            <div className="picTopHeader">
                <img src={imgBKTH}/>
            </div>
            <div className="presentationHeader">
                <div className="imgProfileContainer">
                    <img src={imgProfile}/>
                </div>
                <div className="textPresentation">
                   <div className="presentationText">
                        <h1>Alan Rojas</h1>
                        <p className="textTwoPresentation">Web Developer | PHP | JavaScript</p>
                        <p className="textTreePresentation">General Guido, Provincia de Buenos Aires, Argentina</p>
                   </div>
                   <div className="presentationLogos">
                        <img src={imgRobles}/>
                        <p>Paula Robles</p>
                   </div>
                </div>
                <div className="footerHeader">
                    <ContactMe/>
                    <Cv/>
                </div>
            </div>
        </header>
    )

}

export default Header;