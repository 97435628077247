import React from "react";

const Modal = ({ isOpen, onClose, imgSrc, title }) => {
  if (!isOpen) return null;

  return (
    <div className="modalProject">
      <div className="modalContentEd">
        <div className="contentBtnCloseATitleEd">
            <h3>{title}</h3>
            <button className="btnEd" onClick={onClose}>Cerrar</button>
        </div>
        <div className="imgModal">
          <img src={imgSrc} alt="Credencial" />
        </div>
        
      </div>
    </div>
  );
};

export default Modal;
