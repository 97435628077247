import React from "react";
import imgMail from "../../images/mail.webp"

import InconCME from "./inconCME";
import { ReactComponent as svgLnkdn } from "../../images/linkedin-svgrepo-com.svg";
import { ReactComponent as svgGh } from "../../images/github-color-svgrepo-com.svg";
const ModalContact = ({ isOpen, onClose }) => {
  if (!isOpen) return null; 

  return (
    <div className="modalOverlay">
      <div className="modalContent">
        <button onClick={onClose} className="closeButton">Cerrar</button>
        <h2>Contáctame</h2>
        
        <div className="containerP">
            <img src={imgMail}/>
            <p>rivero.rojasalan@gmail.com</p>
        </div>
        <div className="containerP">
            <InconCME SML={svgLnkdn}/>
            <InconCME SML={svgGh}/>
        </div>
      </div>
    </div>
  );
};

export default ModalContact;
