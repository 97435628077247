const API_URL = 'https://coderalan.com/b/apiProjects.php';

const fetchProjects = async () => {
  try {
    const response = await fetch(API_URL);
    if (!response.ok) {
      throw new Error(`Error en la red: ${response.status}`);
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error('Error al obtener proyectos:', error);
    return [];
  }
};

export default fetchProjects;
