import React from "react";

const IconP = ({SvgIconC}) => {

    return(
        <div className="containerSvgPP">
            <SvgIconC  className="svgPP"/>
        </div>
    )

}

export default IconP;