import React, { useState } from "react";
import Modal from "./ModalContact"; 

const ContactMe = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="containerButtonCMe">
            <button onClick={openModal}>Conectar</button>
            <Modal isOpen={isModalOpen} onClose={closeModal} />
        </div>
    );
};

export default ContactMe;
