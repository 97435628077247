import React from "react";

const InconCME = ({SML}) => {

    return(
        <div>
            <SML className="svgLinkedin" />
        </div>
    )

}


export default InconCME;