import './App.css';
import Header from './components/Header/Header';
import AboutMe from './components/AboutMe/AboutMe'
import Stack from './components/Stack/Stack';
import Projects from './components/Projects/Projects';
import Education from './components/Education/Education';


function App() {
  return (
    <div className="App">
      <Header/>
      <AboutMe/>
      <Stack/>
      <Projects/>
      <Education/>
    </div>
  );
}

export default App;
