import React, { useState } from 'react';

const AboutMe = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const mainText = `Desarrollador Full Stack con 3 años de experiencia en la creación de aplicaciones web utilizando PHP, JavaScript y SQL. Me enfoco en soluciones eficientes y escalables, priorizando la simplicidad y robustez del código.`;
  const additionalText = `Manejo patrones de arquitectura como MVC y creo firmemente en la importancia del código limpio y modular. He trabajado de manera independiente en proyectos para el sector público y privado, adaptándome a las necesidades de seguridad y accesibilidad.`;

  return (
    <article className='AboutMe'>
      <h2>Sobre mí</h2>
      <p>
        {mainText}
      </p>
      {isExpanded && (
        <p>
          {additionalText}
        </p>
      )}
    <div className='CB'>
      <button onClick={toggleReadMore} className='readMore'>
        {isExpanded ? 'ver menos' : '... ver más'}
      </button>
    </div>
    </article>
  );
}

export default AboutMe;
