import React from "react";

const Tech = ({name, SvgIcon}) => {
    return(
        <div className="tech">
            <SvgIcon  className="svg"/>
            <p>{name}</p>
        </div>
    )
}

export default Tech;